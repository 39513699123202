<template>
  <div>
    <vue-ueditor-wrap v-model="description" :config="editorConfig" style="line-height:initial;"></vue-ueditor-wrap>
    <div id="hiddenContainer" style="display:none;">

    </div>
  </div>
</template>
<script>
  import VueUeditorWrap from 'vue-ueditor-wrap'
  export default {
    components: {
      VueUeditorWrap //注册组件
    },
    props: {
      editor: {
        type: String,
        required: false,
      }
    },
    model:{
      prop:'editor',
      event: 'input',
    },
    watch: {
      editor:{
        handler(val, oldVal)  {
          this.description = val
        },
        immediate: true,
          deep: true
      },
      description(val, oldVal) {
        this.$emit('input', val)
      }
    },
    data() {
      return {
        message: '',
        description: '',
        editorConfig: {
          // 编辑器不自动被内容撑高
          autoHeightEnabled: false,
          // 初始容器高度
          initialFrameHeight: 500,
          // 初始容器宽度
          initialFrameWidth: '100%',
          //启用自动保存
          enableAutoSave: false,
          //是否启用元素路径，默认是显示
          elementPathEnabled: false,
          //是否开启字数统计
          wordCount: false,
          // 上传文件接口
          serverUrl: `${this.baseURL}/api/services/app/UEditorHandler/UEditorUpload`,
          UEDITOR_HOME_URL: '/ueditor/',
          toolbars: [
            [
              'fullscreen', 'source', '|', 'undo', 'redo', '|',
              'bold', 'italic', 'underline', 'fontborder', 'strikethrough', '|',
              'removeformat', 'formatmatch', 'autotypeset', 'blockquote', 'pasteplain', '|', 'forecolor', 'backcolor',
              'insertorderedlist', 'insertunorderedlist', 'selectall', 'cleardoc', '|',
              'rowspacingtop', 'rowspacingbottom', 'lineheight', 'touppercase', 'tolowercase', '|',
              'customstyle', 'paragraph', 'fontfamily', 'fontsize', '|',
              'directionalityltr', 'directionalityrtl', 'indent', '|',
              'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|',
              'link', 'unlink', 'anchor', '|', 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
              'simpleupload', 'insertimage', 'emotion', 'map', 'pagebreak', 'template', 'background', '|',
              'horizontal', 'date', 'time', 'spechars', '|',
              'inserttable', 'deletetable', 'insertparagraphbeforetable', 'insertrow', 'deleterow', 'insertcol',
              'deletecol', 'mergecells', 'mergeright', 'mergedown', 'splittocells', 'splittorows', 'splittocols',
              '|',
              'print', 'preview', 'searchreplace'
            ]
          ]
        }
      }
    },
    created() {},
    mounted() {
      window.onCatchStart = this.onCatchStart
      window.onCatchComplete = this.onCatchComplete
      window.onCatchError = this.onCatchError
    },
    methods: {
      onCatchStart() {
        this.message = this.$message({
          type: 'warning',
          duration: 0,
          showClose: true,
          message: '图片正在处理中，还不能保存哦~'
        })
      },
      onCatchComplete() {
        this.message.close()
        this.$message({
          type: 'success',
          message: '图片处理完成，可以保存啦~'
        })
      },
      onCatchError(errorMessage) {
        this.message.close()
        this.$message({
          type: 'error',
          message: '图片处理失败：' + errorMessage
        })
      }
    }
  }

</script>
